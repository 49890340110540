<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <div class="cont">
        <!-- <div class="title" v-if="type == 1">{{ info.title }}</div> -->
        <div v-html="info.content"></div>
      </div>
    </div>
  </div>
</template>
  <script>
/**
 * Created by www.xingnuocn.com
 * @title：轮播图详情
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-18 16:31
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  inject: ["reload"], // 刷新页面
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        // {
        //   title_zh: "网站首页",
        //   title_en: "Homepage",
        //   path: "/",
        // },
        // {
        //   title_zh: "详情",
        //   title_en: "details",
        //   path: "",
        // },
      ],
      info: "", // 详情
      type: "", // 1:轮播图  2:行业科普-DRAM是什么
    };
  },
  mounted() {
    this.type = this.$route.query.type;
    if (this.type == 1) {
      this.sibItem = [
        {
          title_zh: "网站首页",
          title_en: "Homepage",
          path: "/",
        },
        {
          title_zh: "详情",
          title_en: "details",
          path: "",
        },
      ];
      this.info = JSON.parse(this.$route.query.info);
      this.info.content = this.info.content.replace(
        new RegExp("<img", "g"),
        "<img style='max-width:100%;height:auto;margin:0 auto;'"
      );
    }
    if (this.type == 2) {
      this.sibItem = [
        {
          title_zh: "行业服务",
          title_en: "Industry service",
          path: "industryService",
        },
        {
          title_zh: "行业科普",
          title_en: "Industry science popularization",
          path: "sciencePopularization",
        },
        {
          title_zh: "DRAM是什么",
          title_en: "What is DRAM",
          path: "",
        },
      ];
      this.$request({
        url: "/Index/getKepu",
        data: {},
      }).then((res) => {
        console.log(res);
        this.info = res;
        this.info.content = res.content.replace(
          new RegExp("<img", "g"),
          "<img style='max-width:100%;height:auto;margin:0 auto;'"
        );
      });
    }
  },
  methods: {},
};
</script>
  <style lang='scss' scoped>
.outer {
  background: #f6f6f6;
  padding: 78px 310px 120px;
  // display: flex;
  // align-items: flex-start;
}

.cont {
  background: #ffffff;
  padding: 58px;
  flex: 1;

  .title {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #222222;
    margin-bottom: 60px;
  }
}
</style>